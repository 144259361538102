<template>
  <UCard
    class="ring-0 shadow-none flex-1 flex flex-col min-w-1 min-h-1"
    :ui="{
      body: {
        base: 'overflow-auto min-h-full scroll-stable minimal-scrollbar',
      },
    }"
  >
    <template #header>
      <BoardSettingsHeader :allow-go-back="false" hash="settings-fields" :title="fieldTitle" />
    </template>
    <div class="space-y-6">
      <p class="text-sm text-gray-500">
        This field will be added in all task types.
      </p>
    </div>
    <div class="w-full mt-6">
      <div class="mt-6">
        <FormFieldBox
          v-if="selectedField && FieldFormComponents.has(selectedField.type)"
          :field="!!fieldDetail?.id ? fieldDetail : { type: selectedField.type }"
          :loading="isCreatingField"
          :show-wrapper="false"
          :allow-remove="allowRemove"
          :scope="'board'"
          @select="(type) => (selectedFieldType = type)"
          @remove="onDeleteField"
          @submit="onSubmitField"
        />
      </div>
    </div>
  </UCard>
</template>

<script lang="ts" setup>
import { compact } from 'lodash-es'
import { FieldLists, FieldFormComponents, FieldType } from '#field/constant'
import type { CreateFieldParams, UpdateFieldParams, Field } from '#field/types'
import { TaskView } from '#task/constant'

const props = defineProps({
  workspaceId: {
    type: String,
    required: true,
  },
  parentId: {
    type: String,
    required: true,
  },
  source: {
    type: String as PropType<MorphSource>,
    required: true,
  },
})

const toast = useToast()
const route = useRoute()
const { boardData, selectedFieldColumn, updateViewOptions, viewOptionsRaw, toggleFieldVisibility, isFieldVisible } = useBoardSharedState()
const { closeSettings } = useBoardSettingsNavigator()
const { createFieldAndAttach, updateField, unattachFieldFromTaskType, } = useFieldOperation()
const { activeView, currentBoard } = useWorkspaceSharedState()
const { columns, } = useBoardColumnFields({
  activeView: activeView.value,
  boardId: currentBoard.value.id,
})

const fieldDetail = reactive<Field>({
  id: '',
  name: '',
  type: FieldType.TEXT,
  description: '',
  options: [],
})

const allowRemove = ref(false)
const selectedFieldType = ref<FieldType>(FieldLists[0].type)
const isCreatingField = ref(false)

const taskTypeIds = computed(() => boardData.value.taskTypes.filter((taskType) => !taskType.settingsPack).map((taskType) => taskType.id))
const fieldTitle = computed(() => {
  return fieldDetail.id ? 'Edit field' : 'Create a field'
})
const selectedField = computed(() =>
  FieldLists.find((field) => field.type === selectedFieldType?.value)
)
const id = computed(() => route.query.fieldId)

const { load, onResult } = useFieldLazyQuery(id.value as string)
onResult((result) => {
  if (result.data?.field) {
    extend(fieldDetail, result.data?.field)
    selectedFieldType.value = fieldDetail.type
    allowRemove.value = true
  }
})

const onSubmitField = async (
  event: CreateFieldParams | UpdateFieldParams,
  form: Record<string, CallableFunction>
) => {
  if (fieldDetail.id) {
    return onUpdateField(fieldDetail.id, event as UpdateFieldParams, form)
  }

  return onCreateFieldAndAttach(event as CreateFieldParams, form)
}

const arrangeViewOption = (fieldId?: string) => {
  if (!selectedFieldColumn.value || !fieldId) {
    return
  }

  const listKeys = compact(columns.value.map((column) =>
    'key' in column ? column.key : ''
  ))
  const viewOptionData = viewOptionsRaw.value?.[activeView.value]

  const currentOrder = viewOptionData?.order || listKeys
  const currentIndex = currentOrder?.indexOf(selectedFieldColumn.value)

  if (currentIndex === -1) {
    return
  }

  const newOrder = [...currentOrder]
  newOrder.splice(currentIndex + 1, 0, fieldId)

  if (viewOptionData) {
    if (!isFieldVisible(fieldId, activeView.value) && activeView.value === TaskView.TIMELINE) {
      toggleFieldVisibility(fieldId, activeView.value)
    }

    extend(viewOptionData, {
      order: compact(newOrder),
    })

    updateViewOptions(
      `${activeView.value}`,
      viewOptionData,
      {
        boardId: currentBoard.value.id,
      }
    )
  }
}

const onCreateFieldAndAttach = async (
  event: CreateFieldParams,
  form: Record<string, CallableFunction>
) => {
  isCreatingField.value = true
  const response = await createFieldAndAttach(
    {
      ...event,
      workspace: props.workspaceId,
    },
    taskTypeIds.value,
  )
  if (!response.success) {
    isCreatingField.value = false
    return form.setErrors(parseGqlErrors(response.error))
  }

  arrangeViewOption(response.data?.id)

  closeSettings()
  toast.add({
    title: `Custom field “${event.name}” has been successfully created`,
    description: 'This custom field will be applied to all task types across the board.'
  })
  isCreatingField.value = false
}

const onUpdateField = async (
  id: string,
  event: UpdateFieldParams,
  form: Record<string, CallableFunction>
) => {
  const response = await updateField(id, event)
  if (!response.success) {
    return form.setErrors(parseGqlErrors(response.error))
  }

  closeSettings()
  toast.add({
    title: 'Updated field successfully',
  })
}

const onDeleteField = async () => {
  if (!fieldDetail.id) {
    return closeSettings()
  }

  await Promise.all(taskTypeIds.value.map((taskTypeId) => unattachFieldFromTaskType(fieldDetail, taskTypeId)))

  toast.add({
    title: 'Deleted field successfully',
  })
  closeSettings()
}

onMounted(() => {
  if (id.value) {
    load()
  }
})
</script>
